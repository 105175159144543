import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 640.000000 640.000000" >
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,640.000000) scale(0.100000,-0.100000)" >


<path d="M1875 4338 c-8 -22 -306 -1511 -312 -1555 -5 -37 -3 -43 13 -43 89
-2 499 1 501 4 2 1 28 126 58 277 30 151 57 280 60 287 3 10 51 12 212 10
l208 -3 -2 -22 c-2 -13 3 -23 10 -23 6 0 124 -1 262 -2 l249 -3 9 40 c4 22 28
141 52 265 24 124 69 346 99 494 31 148 56 273 56 278 0 5 -118 7 -262 6
l-261 -3 -53 -263 c-29 -145 -55 -270 -58 -278 -4 -11 -43 -14 -210 -14 -113
0 -207 4 -210 8 -3 4 20 129 49 278 30 148 55 270 55 272 0 1 -117 2 -260 2
-200 0 -262 -3 -265 -12z"/>
<path d="M3723 4331 c-112 -32 -196 -104 -257 -220 -119 -227 -62 -457 175
-711 86 -92 94 -139 30 -169 -32 -14 -100 -16 -553 -19 l-516 -3 -7 -27 c-12
-47 -85 -416 -85 -430 0 -10 153 -12 778 -10 l777 3 70 36 c75 38 137 103 189
199 113 208 55 459 -163 703 -34 37 -68 82 -77 98 -14 27 -14 33 0 53 8 13 29
26 47 30 17 3 163 6 323 6 160 0 296 4 302 8 12 8 104 458 96 467 -3 3 -244 5
-536 4 -478 0 -537 -2 -593 -18z"/>
<path d="M792 2418 l3 -63 43 -3 42 -3 0 -150 0 -150 68 3 67 3 5 75 5 75 50
0 50 0 5 -75 5 -75 65 0 65 0 3 213 2 212 -70 0 -70 0 0 -75 0 -75 -55 0 -55
0 0 75 0 75 -115 0 -116 0 3 -62z"/>
<path d="M1372 2270 c-52 -155 -67 -212 -58 -216 7 -3 42 -4 77 -2 63 3 64 3
75 38 11 35 12 35 76 38 l65 3 16 -41 15 -40 76 0 c54 0 76 4 76 13 0 6 -30
102 -68 212 l-68 200 -106 3 -106 3 -70 -211z m206 28 c18 -57 20 -73 10 -80
-18 -11 -85 -10 -92 1 -7 11 43 151 53 151 3 0 16 -33 29 -72z"/>
<path d="M1830 2265 l0 -215 65 0 65 0 0 80 c0 44 3 80 8 80 4 0 24 -34 45
-75 22 -41 42 -75 46 -75 4 0 24 35 46 78 l40 77 5 -80 5 -80 68 -3 67 -3 0
216 0 215 -69 0 -69 0 -44 -85 c-23 -47 -45 -85 -48 -85 -3 0 -26 38 -50 85
l-45 85 -67 0 -68 0 0 -215z"/>
<path d="M2362 2268 l3 -213 170 0 170 0 46 32 c47 32 59 53 79 130 22 86 -14
186 -83 230 -41 27 -46 28 -215 31 l-172 4 2 -214z m287 76 c15 -20 21 -41 21
-81 0 -76 -27 -103 -105 -103 l-55 0 0 105 0 105 59 0 c52 0 62 -3 80 -26z"/>
<path d="M2928 2273 c-37 -113 -65 -210 -62 -214 3 -5 36 -9 73 -9 l68 0 16
40 15 40 61 0 c60 0 61 0 78 -37 18 -38 18 -38 87 -41 38 -2 73 1 77 5 4 4
-23 100 -62 213 -38 113 -69 206 -69 208 0 1 -48 2 -107 2 l-108 -1 -67 -206z
m205 25 c14 -40 23 -76 21 -80 -7 -12 -92 -10 -99 2 -7 11 36 150 46 150 4 0
18 -32 32 -72z"/>
<path d="M3390 2265 l0 -215 185 0 185 0 0 65 0 65 -115 0 -115 0 0 150 0 150
-70 0 -70 0 0 -215z"/>
<path d="M3915 2468 c-38 -100 -132 -405 -126 -411 4 -4 39 -7 76 -5 68 3 68
3 80 38 12 35 12 35 76 38 l64 2 17 -40 17 -40 75 0 c42 0 76 4 76 8 0 8 -121
371 -135 405 -6 15 -22 17 -111 17 -75 0 -106 -4 -109 -12z m142 -175 c12 -37
20 -70 17 -75 -9 -14 -94 -9 -100 5 -3 8 4 36 14 63 10 27 22 58 26 68 9 26
17 15 43 -61z"/>
<path d="M4360 2467 c-15 -7 -31 -29 -39 -53 -13 -38 -13 -44 7 -85 12 -24 40
-64 62 -88 22 -24 37 -48 33 -52 -4 -4 -35 -9 -68 -11 l-60 -3 0 -60 0 -60
100 -3 c124 -4 161 3 191 33 49 49 17 162 -61 221 -43 32 -32 43 48 46 l72 3
3 63 3 62 -133 0 c-87 0 -142 -5 -158 -13z"/>
<path d="M4671 2322 c3 -129 7 -163 21 -184 81 -124 304 -124 385 -1 13 19 17
58 21 184 l5 159 -72 0 -71 0 0 -145 c0 -132 -2 -147 -20 -165 -12 -12 -33
-20 -55 -20 -22 0 -43 8 -55 20 -18 18 -20 33 -20 165 l0 145 -71 0 -71 0 3
-158z"/>
<path d="M5160 2265 l0 -216 183 6 c207 7 241 15 262 55 30 58 17 105 -40 140
l-35 22 32 22 c57 38 53 123 -8 163 -24 15 -54 18 -211 21 l-183 4 0 -217z
m258 109 c14 -4 22 -14 22 -29 0 -28 -17 -35 -81 -35 l-49 0 0 35 c0 35 1 35
43 35 23 0 52 -3 65 -6z m36 -170 c36 -35 7 -63 -72 -70 -68 -5 -82 2 -82 40
0 18 3 36 7 39 14 15 131 7 147 -9z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
